// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { TextField, Button, Grid2, Typography, Card, CardMedia, CardContent, CardActions, CircularProgress, Container, Chip } from '@mui/material';
// import amazon from './amazon.png';
// import flipkart from './flipkart.png';
// import './Home.css'; // Import custom styles (if needed)
// import Footer from '../Footer/Footer';
// import Header from '../Header/Header';

// const Home = () => {
//   const [query, setQuery] = useState('');
//   const [matchedProduct, setMatchedProduct] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [fact, setFact] = useState('');

//   // Fetch random trivia or fact (shopping-related API can be used if available)
//   const fetchDidYouKnowFact = async () => {
//     try {
//       const response = await axios.get('https://api.api-ninjas.com/v1/facts?limit=1', {
//         headers: { 'X-Api-Key': 'se+KF3PP4t84ma8QHqMctw==dYtgiHOeYcZrNngX' } // Replace with your API key if needed
//       });
//       setFact(response.data[0].fact); // Adjust based on the API response format
//     } catch (error) {
//       console.error('Error fetching fact:', error);
//       setFact('Shopping tip: Compare prices on different platforms for the best deal!');
//     }
//   };

//   // Fetch the trivia fact on component mount
//   useEffect(() => {
//     fetchDidYouKnowFact();
//   }, []);

//   // Handle the search form submission
//   const handleSearch = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setHasSearched(true);

//     try {
//       const apiResponse = await axios.get(`https://cart-compare-node.onrender.com/api/compare-products?query=${query}`);

//       // Find the product with the highest similarity
//       const highestMatch = apiResponse.data.flipkartMatches.reduce((prev, current) => {
//         return prev.similarity > current.similarity ? prev : current;
//       });

//       setMatchedProduct({
//         amazon: apiResponse.data.amazon,
//         flipkart: highestMatch,
//       });
//     } catch (error) {
//       console.error('Error fetching products:', error);
//     }

//     setLoading(false);
//   };

//   // Handle the reset
//   const handleReset = () => {
//     setQuery('');
//     setMatchedProduct(null);
//     setHasSearched(false);
//   };

//   // Determine the lower price between Amazon and Flipkart
//   const getLowestPriceTag = () => {
//     if (matchedProduct) {
//       const amazonPrice = parseFloat(matchedProduct.amazon.price.replace(/[^\d.-]/g, ''));
//       const flipkartPrice = parseFloat(matchedProduct.flipkart.price);
//       return amazonPrice < flipkartPrice ? 'amazon' : 'flipkart';
//     }
//     return null;
//   };

//   const lowestPriceTag = getLowestPriceTag();

//   return (
//     <Container maxWidth="lg" sx={{ paddingBottom: '6em', marginTop: '7em' }}>
//       <Header />

//       <form onSubmit={handleSearch}>
//         <Grid2 container spacing={2} justifyContent="center" marginTop="6em">
//           <Grid2 size={{ xs: 12, sm: 8, md: 6 }}>
//             <TextField
//               fullWidth
//               label="Search for a product"
//               variant="outlined"
//               value={query}
//               onChange={(e) => setQuery(e.target.value)}
//               sx={{
//                 '& .MuiOutlinedInput-root': {
//                   '& fieldset': {
//                     borderColor: '#1abc9c',
//                   },
//                   '&:hover fieldset': {
//                     borderColor: '#16a085',
//                   },
//                 },
//               }}
//             />
//           </Grid2>
//           <Grid2 size={{ xs: 12, sm: 4, md: 3 }}>
//             <Button
//               variant="contained"
//               color="primary"
//               size="large"
//               type="submit"
//               disabled={loading}
//               sx={{
//                 backgroundColor: '#1abc9c',
//                 '&:hover': {
//                   backgroundColor: '#16a085',
//                 },
//                 width: '100%',
//               }}
//             >
//               Search
//             </Button>
//           </Grid2>
//           <Grid2 size={{ xs: 12, sm: 4, md: 3 }}>
//             <Button
//               variant="outlined"
//               color="secondary"
//               size="large"
//               onClick={handleReset}
//               sx={{
//                 width: '100%',
//               }}
//             >
//               Reset
//             </Button>
//           </Grid2>
//         </Grid2>
//       </form>

//       {loading ? (
//         <Grid2 container justifyContent="center" style={{ marginTop: '20px' }}>
//           <CircularProgress />
//         </Grid2>
//       ) : matchedProduct ? (
//         <Grid2 container spacing={4} justifyContent="center" style={{ marginTop: '40px' }}>
//           <Grid2 size={{ xs: 12, sm: 6 }}>
//             <Card
//               sx={{
//                 boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//                 transition: 'transform 0.3s',
//                 position: 'relative', // Ensure Chip is positioned correctly
//               }}
//             >
//               {lowestPriceTag === 'amazon' && (
//                 <Chip
//                   label="Lowest Price"
//                   color="success"
//                   sx={{ position: 'absolute', top: 10, left: 10 }}
//                 />
//               )}
//               <CardMedia
//                 component="img"
//                 image={matchedProduct.amazon.photo || amazon}
//                 alt={matchedProduct.amazon.title}
//                 style={{ objectFit: 'contain', height: '200px', padding: '10px' }}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div">
//                   {matchedProduct.amazon.title}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   Price: {matchedProduct.amazon.price}
//                 </Typography>
//               </CardContent>
//               <CardActions>
//                 {matchedProduct.amazon.url && (
//                   <Button
//                     size="small"
//                     color="primary"
//                     variant="outlined"
//                     startIcon={<img src={amazon} alt="Amazon Logo" style={{ width: '24px', height: '24px' }} />}
//                     href={matchedProduct.amazon.url}
//                     target="_blank"
//                     sx={{
//                       backgroundColor: '#3498db',
//                       color: '#fff',
//                       '&:hover': {
//                         backgroundColor: '#2980b9',
//                       },
//                     }}
//                   >
//                     View on Amazon
//                   </Button>
//                 )}
//               </CardActions>
//             </Card>
//           </Grid2>

//           <Grid2 size={{ xs: 12, sm: 6 }}>
//             <Card
//               sx={{
//                 boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//                 transition: 'transform 0.3s',
//                 position: 'relative', // Ensure Chip is positioned correctly
//               }}
//             >
//               {lowestPriceTag === 'flipkart' && (
//                 <Chip
//                   label="Lowest Price"
//                   color="success"
//                   sx={{ position: 'absolute', top: 10, left: 10 }}
//                 />
//               )}
//               <CardMedia
//                 component="img"
//                 image={matchedProduct.flipkart.photo || flipkart}
//                 alt={matchedProduct.flipkart.title}
//                 style={{ objectFit: 'contain', height: '200px', padding: '10px' }}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div">
//                   {matchedProduct.flipkart.title}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   Price: ₹{matchedProduct.flipkart.price}
//                 </Typography>
//               </CardContent>
//               <CardActions>
//                 {matchedProduct.flipkart.url && (
//                   <Button
//                     size="small"
//                     color="primary"
//                     variant="outlined"
//                     startIcon={<img src={flipkart} alt="Flipkart Logo" style={{ width: '24px', height: '24px' }} />}
//                     href={matchedProduct.flipkart.url}
//                     target="_blank"
//                     sx={{
//                       backgroundColor: '#3498db',
//                       color: '#fff',
//                       '&:hover': {
//                         backgroundColor: '#2980b9',
//                       },
//                     }}
//                   >
//                     View on Flipkart
//                   </Button>
//                 )}
//               </CardActions>
//             </Card>
//           </Grid2>
//         </Grid2>
//       ) : (
//         <Grid2 container justifyContent="center" style={{ marginTop: '40px' }}>
//           <Typography variant="h6" align="center" color="textPrimary">
//             {hasSearched ? "No products found" : "Find the cheapest option across platforms!"}
//           </Typography>
//           {loading && (
//             <Typography variant="h6" align="center" color="textSecondary" style={{ marginTop: '20px' }}>
//               Did you know? {fact}
//             </Typography>
//           )}
//         </Grid2>
//       )}

//       <Footer />
//     </Container>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Container,
  Chip,
  Skeleton,
} from "@mui/material";
import amazon from "./amazon.png";
import flipkart from "./flipkart.png";
import "./Home.css"; // Import custom styles (if needed)
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Home = () => {
  const [query, setQuery] = useState("");
  const [matchedProduct, setMatchedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [fact, setFact] = useState("");

  // Fetch random trivia or fact (shopping-related API can be used if available)
  const fetchDidYouKnowFact = async () => {
    try {
      const response = await axios.get("https://api.api-ninjas.com/v1/facts", {
        headers: { "X-Api-Key": "se+KF3PP4t84ma8QHqMctw==dYtgiHOeYcZrNngX" }, // Replace with your API key stored in environment variables
      });
      console.log(response.data);
      setFact(response.data[0].fact); // Adjust based on the API response format
    } catch (error) {
      console.error("Error fetching fact:", error);
      setFact(
        "Shopping tip: Compare prices on different platforms for the best deal!"
      );
    }
  };

  // Fetch the trivia fact on component mount
  useEffect(() => {
    fetchDidYouKnowFact();
  }, []);

  // Handle the search form submission
  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setHasSearched(true);

    try {
      const apiResponse = await axios.get(
        `https://cart-compare-node.onrender.com/api/compare-products?query=${query}`
      );

      // Find the product with the highest similarity
      const highestMatch = apiResponse.data.flipkartMatches.reduce(
        (prev, current) => {
          return prev.similarity > current.similarity ? prev : current;
        }
      );

      setMatchedProduct({
        amazon: apiResponse.data.amazon,
        flipkart: highestMatch,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    }

    setLoading(false);
  };

  // Handle the reset
  const handleReset = () => {
    setQuery("");
    setMatchedProduct(null);
    setHasSearched(false);
  };

  // Determine the lower price between Amazon and Flipkart
  const getLowestPriceTag = () => {
    if (matchedProduct) {
      const amazonPrice = parseFloat(
        matchedProduct.amazon.price.replace(/[^\d.-]/g, "")
      );
      const flipkartPrice = parseFloat(matchedProduct.flipkart.price);
      return amazonPrice < flipkartPrice ? "amazon" : "flipkart";
    }
    return null;
  };

  const lowestPriceTag = getLowestPriceTag();

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "6em", marginTop: "7em" }}>
      <Header />

      <form onSubmit={handleSearch}>
        <Grid container spacing={2} justifyContent="center" marginTop="6em">
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              fullWidth
              label="Search for a product"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "6vh",
                  "& fieldset": {
                    borderColor: "#1abc9c",
                  },
                  "&:hover fieldset": {
                    borderColor: "#16a085",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading}
              sx={{
                backgroundColor: "#1abc9c",
                "&:hover": {
                  backgroundColor: "#16a085",
                },
                width: "100%",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleReset}
              sx={{
                width: "100%",
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>

      {loading ? (<>
        <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            sx={{ marginTop: "1.5em", fontSize: "1.2rem" }} // Adjust the font size
          >
            Did you know? {fact}
          </Typography>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ marginTop: "40px" }}
        >

          {/* Skeleton Loader for Amazon Card */}
          <Grid item xs={12} sm={5} md={5}>
            <Card
              sx={{
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s",
                position: "relative",
              }}
            >
              <Skeleton variant="rectangular" height={200} />
              <CardContent>
                <Skeleton variant="text" height={40} width="80%" />
                <Skeleton variant="text" height={20} width="60%" />
              </CardContent>
              <CardActions>
                <Skeleton variant="rectangular" height={36} width={120} />
              </CardActions>
            </Card>
          </Grid>

          {/* Skeleton Loader for Flipkart Card */}
          <Grid item xs={12} sm={5} md={5}>
            <Card
              sx={{
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s",
                position: "relative",
              }}
            >
              <Skeleton variant="rectangular" height={200} />
              <CardContent>
                <Skeleton variant="text" height={40} width="80%" />
                <Skeleton variant="text" height={20} width="60%" />
              </CardContent>
              <CardActions>
                <Skeleton variant="rectangular" height={36} width={120} />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </>) : matchedProduct ? (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{ marginTop: "40px" }}
        >
          <Grid item xs={12} sm={5} md={5}>
            <Card
              sx={{
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s",
                position: "relative",
              }}
            >
              {lowestPriceTag === "amazon" && (
                <Chip
                  label="Lowest Price"
                  color="success"
                  sx={{ position: "absolute", top: 10, left: 10 }}
                />
              )}
              <CardMedia
                component="img"
                image={matchedProduct.amazon.photo || amazon}
                alt={matchedProduct.amazon.title}
                style={{
                  objectFit: "contain",
                  height: "200px",
                  padding: "10px",
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {matchedProduct.amazon.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: {matchedProduct.amazon.price}
                </Typography>
              </CardContent>
              <CardActions>
                {matchedProduct.amazon.url && (
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    startIcon={
                      <img
                        src={amazon}
                        alt="Amazon Logo"
                        style={{ width: "24px", height: "24px" }}
                      />
                    }
                    href={matchedProduct.amazon.url}
                    target="_blank"
                    sx={{
                      backgroundColor: "#3498db",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#2980b9",
                      },
                    }}
                  >
                    View on Amazon
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={5} md={5}>
            <Card
              sx={{
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s",
                position: "relative",
                marginBottom: "2em",
              }}
            >
              {lowestPriceTag === "flipkart" && (
                <Chip
                  label="Lowest Price"
                  color="success"
                  sx={{ position: "absolute", top: 10, left: 10 }}
                />
              )}
              <CardMedia
                component="img"
                image={matchedProduct.flipkart.photo || flipkart}
                alt={matchedProduct.flipkart.title}
                style={{
                  objectFit: "contain",
                  height: "200px",
                  padding: "10px",
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {matchedProduct.flipkart.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ₹{matchedProduct.flipkart.price}
                </Typography>
              </CardContent>
              <CardActions>
                {matchedProduct.flipkart.url && (
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    startIcon={
                      <img
                        src={flipkart}
                        alt="Flipkart Logo"
                        style={{ width: "24px", height: "24px" }}
                      />
                    }
                    href={matchedProduct.flipkart.url}
                    target="_blank"
                    sx={{
                      backgroundColor: "#3498db",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#2980b9",
                      },
                    }}
                  >
                    View on Flipkart
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" style={{ marginTop: "40px" }}>
          <Typography variant="h6" align="center" color="textPrimary">
            {hasSearched
              ? "No products found"
              : "Find the cheapest option across platforms!"}
          </Typography>
          {/* {!loading && (
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              style={{ marginTop: '20px' }}
            >
              Did you know? {fact}
            </Typography>
          )} */}
        </Grid>
      )}

      <Footer />
    </Container>
  );
};

export default Home;
