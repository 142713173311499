import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [recentSearches, setRecentSearches] = useState([]);

  const addSearchTerm = (term) => {
    setRecentSearches((prevSearches) => [...prevSearches, term]);
  };

  return (
    <UserContext.Provider value={{ recentSearches, addSearchTerm }}>
      {children}
    </UserContext.Provider>
  );
};
