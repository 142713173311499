import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Avatar } from '@mui/material';
import logo from './logo.png'; // Import your logo image here

const Header = () => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#1abc9c', width: '100%'}}>
      <Toolbar sx={{height: '80px', justifyContent: 'space-between' }}>
        {/* Logo and Title */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt="Website Logo"
            variant='square'
            src={logo} // Your logo image
            sx={{ width: 125, height: 60, marginRight: 2 }} // Logo size
          />
          {/* <Typography variant="h6" component="div">
            Cart Compare
          </Typography> */}
        </Box>

      </Toolbar>
    </AppBar>
  );
};

export default Header;
