import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#1a1a1a',
        color: '#ffffff',
        padding: '12px 0',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        zIndex: 9999,
      }}
    >
      <Container>
        <Typography variant="body1">
          &copy; {new Date().getFullYear()} Created by{' '}
          <Link
            href="https://skmdirfan.com/"
            color="inherit"
            underline="hover"
            target="_blank"
            sx={{
              fontWeight: 'bold',
              '&:hover': { color: '#1abc9c' },
            }}
          >
            Sekh Mohammad Irfan
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
