import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Home from './components/Home/Home';



const App = () => {
  return (
    <UserProvider>
      <Routes>
      <Route exact path="/" element={<Home />} />
      </Routes>
    </UserProvider>
  );
};

export default App;
